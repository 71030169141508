import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useService from "../../hooks/hook-service";
import { showError, showWarning, showWarningDialog } from "../../utils/message";
import GenericInputId from "../../components/GenericComponents/GenericInputId";
import GenericCalendar from "../../components/GenericComponents/GenericCalendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { convertStringToDate, editDate } from "../../utils/format";
import { classNames } from "primereact/utils";

const BeneficiaryForm = ({
  beneficiary,
  contractType,
  onBack,
  onChange,
  isEditing = false,
}) => {
  const [kinships, setKinships] = useState([]);
  const [kinship, setKinship] = useState();
  const [feeTypes, setFeeTypes] = useState([]);

  const { post, manyPost } = useService();

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const idKinship = watch("idKinship");
  const additionalFeeId = watch("additionalFeeId");

  useEffect(() => {
    const request = [
      {
        url: "catalog/list",
        body: {
          catalogType: "1001",
        },
      },
      {
        url: "catalog/list",
        body: {
          catalogType: "1010",
          configuration: 1
        },
      },
    ];
    manyPost(request, (result) => {
      setKinships(result[0].catalogs);
      setFeeTypes(result[1].catalogs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kinships.length > 0 && idKinship) {
      setKinship(kinships.find((x) => x.id === idKinship));
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kinships, idKinship]
  );

  useEffect(
    () => {
      reset({
        idKinship: beneficiary?.kinship?.id ?? "",
        idCard: beneficiary?.idCard ?? "",
        lastName: beneficiary?.lastName ?? "",
        firstName: beneficiary?.firstName ?? "",
        birthDate: editDate(beneficiary?.birthDate) ?? "",
        feeAmount: beneficiary?.feeAmount ?? "",
        additionalFeeId: beneficiary?.additionalFee?.id ?? ""
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [beneficiary]
  );

  const searchPerson = () => {
    const data = {
      idCard: getValues("idCard"),
    };

    post("persons/get", data, ({ person }) => {
      if (!person) {
        showWarning("No se encontro ninguna persona con esa cedula");
        return;
      }

      setValue("firstName", person.firstName);
      setValue("lastName", person.lastName);
    });
  };

  const askToGoBack = () => {
    if (isDirty === true) {
      showWarningDialog(
        {
          title: "Precaución",
          text: "Existen cambios en el edición de un beneficiario, ¿Está seguro(a) de que desea salir?",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        },
        {
          confirm: () => {
            onBack();
          },
        }
      );
    } else {
      onBack();
    }
  };

  const onSubmit = (formData) => {
    const additionalFee = feeTypes.find((x) => x.id === formData.additionalFeeId);
    const beneficiary = {
      idCard: formData.idCard,
      firstName: formData.firstName,
      birthDate: convertStringToDate(formData.birthDate),
      lastName: formData.lastName,
      kinship: kinship,
      feeAmount: formData.feeAmount,
      additionalFee: additionalFee
    };
    onChange(beneficiary);
    reset();
  };

  const onError = (errors) => {
    showError("Debe completar los campos");
  };

  const shouldShowAdditionalFee = () => {
    return kinship?.allowAdditionalQuote;
  };

  const shouldAddIdCard = () => {
    if (!kinship)
      return true;

    return kinship?.shouldAddIdCard;
  };

  const shouldAllowAnyAmount = () => feeTypes?.find(x => x.id === additionalFeeId)?.allowAnyAmount;

  const shouldShowAnyAmountFee = () => {
    const allowAnyAmount = shouldAllowAnyAmount();

    if (!allowAnyAmount && getValues("feeAmount")) {
      setValue("feeAmount", '');
    }

    return allowAnyAmount;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="grid">
      <div className="col-12 md:col-2">
        <label htmlFor="input">Parentesco</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          control={control}
          name="idKinship"
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              id="idKinship"
              {...field}
              className={classNames({
                "p-invalid": !!errors.idKinship,
              })}
              optionLabel="name"
              optionValue="id"
              placeholder="Parentesco"
              options={kinships}
            />
          )}
        />
      </div>
      {!isEditing && shouldAddIdCard() && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Cedula</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="idCard"
              rules={{ required: true }}
              render={({ field }) => (
                <GenericInputId
                  id="idCard"
                  {...field}
                  className={classNames({
                    "p-invalid": !!errors.idCard,
                  })}
                />
              )}
            />
            <Button type="button" label="Buscar" onClick={searchPerson} />
          </div>
        </>
      )}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Nombre</label>
      </div>
      <div className="col-12 md:col-4">
        <InputText
          type="text"
          {...register("firstName", {
            required: true,
          })}
          className={classNames({
            "p-invalid": !!errors.firstName,
          })}
        />
      </div>
      {shouldAddIdCard() && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Apellidos</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true }}
              render={({ field }) => (
                <InputText
                  id="lastName"
                  {...field}
                  className={classNames({
                    "p-invalid": !!errors.lastName,
                  })}
                />
              )}
            />
          </div>
        </>)}
      <div className="col-12 md:col-2">
        <label htmlFor="input">Fecha De Nacimiento</label>
      </div>
      <div className="col-12 md:col-4">
        <Controller
          rules={{ required: true }}
          control={control}
          name="birthDate"
          render={({ field }) => (
            <GenericCalendar
              id="birthDate"
              {...field}
              className={classNames({
                "p-invalid": !!errors.birthDate,
              })}
            />
          )}
        />
      </div>
      {shouldShowAdditionalFee() && (
        <>
          <div className="col-12 md:col-2">
            <label htmlFor="input">Cuota Adicional</label>
          </div>
          <div className="col-12 md:col-4">
            <Controller
              control={control}
              name="additionalFeeId"
              rules={{ required: kinship?.requiredAdditionalFee }}
              render={({ field }) => (
                <Dropdown
                  id="additionalFeeId"
                  {...field}
                  className={classNames({
                    "p-invalid": !!errors.additionalFeeId,
                  })}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Cuota Addicional"
                  options={feeTypes}
                />
              )}
            />
          </div>
          {shouldShowAnyAmountFee() && (
            <>
              <div className="col-12 md:col-2">
                <label htmlFor="input">Monto de Cuota</label>
              </div>
              <div className="col-12 md:col-4">
                <Controller
                  control={control}
                  name="feeAmount"
                  rules={{ required: shouldAllowAnyAmount() }}
                  render={({ field }) => (
                    <InputText
                      id="feeAmount"
                      {...field}
                      className={classNames({
                        "p-invalid": !!errors.feeAmount,
                      })}
                      step="1"
                      min="0"
                    />
                  )}
                />
              </div>
            </>)}
        </>)}

      <div className="md:col-12">
        <Button label="Guardar" />
        <Button type="button" label="Regresar" onClick={askToGoBack} />
      </div>
    </form>
  );
};

export default BeneficiaryForm;
