import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import BeneficiaryForm from "../../components/BeneficiaryForm";
import { showSuccess } from "../../utils/message";

const BeneficiaryEdit = () => {
  const [beneficiary, setBeneficiary] = useState({});
  const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { manyPost, post } = useService();
  const location = useLocation();

  useEffect(() => {
    const request = [
      {
        url: "beneficiary/get",
        body: {
          id: location.state.beneficiary.idCard,
        },
      },
      {
        url: "contract/get",
        body: {
          id: location.state.beneficiary.idContract,
        },
      },
    ];
    manyPost(request, (result) => {
      setBeneficiary(result[0].beneficiary);
      setContract(result[1].contract);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const saveBeneficiary = (bene) => {
    const request = {
      beneficiary: {
        ...beneficiary,
        ...bene,
      },
    };
    post("beneficiary/update", request, () => {
      showSuccess("El beneficiario se ha actualizado correctamente");
      navigate(-1);
    });
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Editar beneficiario</h1>
            </div>
            <div className="spacer20" />
            <BeneficiaryForm
              beneficiary={beneficiary}
              onChange={saveBeneficiary}
              contractType={contract?.type?.id}
              onBack={goBack}
              isEditing={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryEdit;
