/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created By Steven Fonseca <sfonseca@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Controller, useFormContext } from "react-hook-form";
import useService from "../../hooks/hook-service";
import { showError } from "../../utils/message";
import BeneficiaryDetails from "../../components/BeneficiaryDetails";
import BeneficiaryForm from "../../components/BeneficiaryForm";
import { classNames } from "primereact/utils";
import { CONTRACT_TYPES } from "../../constants";
import { useRef } from "react";

const ContractTypeAndBeneficiaries = ({ onSave, onBack, contract, children, label = "Tipo de Contrato" }) => {
    const STATES_ROW_INDEX = {
        CLOSE: -2,
        NEW: -1,
    };
    const [contractTypes, setContractTypes] = useState([]);
    const individualPlusAdditionalContractType = useRef();
    const basicPlusAdditionalContractType = useRef();
    const familiarPlusAdditionalContractType = useRef();

    const [beneficiaries, setBeneficiaries] = useState([]);
    const [beneficiary, setBeneficiary] = useState();
    const [rowIndexBeneficiary, setRowIndexBeneficiary] = useState(
        STATES_ROW_INDEX.CLOSE
    );

    const { post } = useService();
    const { watch, handleSubmit, setValue, control, formState: { errors }, } = useFormContext();
    const contractType = watch("contractType");

    useEffect(() => {
        const request = { catalogType: "1000" };

        post("catalog/list", request, ({ catalogs }) => {
            const types = [];
            catalogs.forEach((element) => {
                if (element.id === CONTRACT_TYPES.BASIC_PLUS) {
                    basicPlusAdditionalContractType.current = element;
                } else if (element.id === CONTRACT_TYPES.INDIVIDUAL_PLUS) {
                    individualPlusAdditionalContractType.current = element;
                } else if (element.id === CONTRACT_TYPES.FAMILIAR_PLUS) {
                    familiarPlusAdditionalContractType.current = element;
                } else {
                    types.push(element);
                }
            });
            setContractTypes(types);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (contract?.type?.id !== contractType) {
            if (contract?.type?.id === CONTRACT_TYPES.BASIC_PLUS) {
                setValue("contractType", basicPlusAdditionalContractType.current.id);
                contractTypes.push(basicPlusAdditionalContractType.current);
                setContractTypes([...contractTypes]);
            } else if (contract?.type?.id === CONTRACT_TYPES.INDIVIDUAL_PLUS) {
                setValue("contractType", individualPlusAdditionalContractType.current.id);
                contractTypes.push(individualPlusAdditionalContractType.current);
                setContractTypes([...contractTypes]);
            } else if (contract?.type?.id === CONTRACT_TYPES.FAMILIAR_PLUS) {
                setValue("contractType", familiarPlusAdditionalContractType.current.id);
                contractTypes.push(familiarPlusAdditionalContractType.current);
                setContractTypes([...contractTypes]);
            } else {
                setValue("contractType", contract?.type?.id);
            }
            setBeneficiaries(contract?.affiliate?.beneficiaries);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract]);

    const manualContractChange = (contractTypeSelected) => {
        if (![CONTRACT_TYPES.BASIC_PLUS, CONTRACT_TYPES.INDIVIDUAL_PLUS, CONTRACT_TYPES.FAMILIAR_PLUS].includes(contractTypeSelected)) {
            removeContractTypes();
            setBeneficiaries([]);
            setValue("contractType", contractTypeSelected);
        }
    };

    const showAddBeneficiary = () => {
        setRowIndexBeneficiary(STATES_ROW_INDEX.NEW);
        setBeneficiary(null);
    };

    const showEditBeneficiary = (beneficiary, index) => {
        setRowIndexBeneficiary(index);
        setBeneficiary(beneficiary);
    };

    const removeContractTypes = () => {
        const contracts = contractTypes;
        const index = contracts.findIndex(
            (contra) => [CONTRACT_TYPES.BASIC_PLUS, CONTRACT_TYPES.INDIVIDUAL_PLUS, CONTRACT_TYPES.FAMILIAR_PLUS].includes(contra.id)
        );
        if (index !== -1) {
            contracts.splice(index, 1);
            setContractTypes([...contracts]);
        }
    };

    const checkIfRemoveBasicPlusAdditional = (contractType, beneficiaries) => {
        if (
            beneficiaries.filter((item) => item.additionalFee).length === 0 &&
            [CONTRACT_TYPES.BASIC_PLUS, CONTRACT_TYPES.INDIVIDUAL_PLUS, CONTRACT_TYPES.FAMILIAR_PLUS].includes(contractType)
        ) {
            removeContractTypes();
            const newContractType = contractType === CONTRACT_TYPES.INDIVIDUAL_PLUS ? CONTRACT_TYPES.INDIVIDUAL :
                contractType === CONTRACT_TYPES.BASIC_PLUS ? CONTRACT_TYPES.BASIC : CONTRACT_TYPES.FAMILIAR;
            setValue("contractType", newContractType);
        }
    };

    const checkIfChangePlusAdditional = (benef) => {
        if (!benef.additionalFee) {
            return;
        }

        if (contractType === CONTRACT_TYPES.BASIC) {
            setValue("contractType", basicPlusAdditionalContractType.current.id);
            contractTypes.push(basicPlusAdditionalContractType.current);
            setContractTypes([...contractTypes]);
        } else if (contractType === CONTRACT_TYPES.INDIVIDUAL) {
            setValue("contractType", individualPlusAdditionalContractType.current.id);
            contractTypes.push(individualPlusAdditionalContractType.current);
            setContractTypes([...contractTypes]);
        } else if (contractType === CONTRACT_TYPES.FAMILIAR) {
            setValue("contractType", familiarPlusAdditionalContractType.current.id);
            contractTypes.push(familiarPlusAdditionalContractType.current);
            setContractTypes([...contractTypes]);
        }
    };

    const hasExceededLimitOfBeneficiaries = (benef) => {
        return false
    };

    const addBeneficiary = (benef) => {
        if (hasExceededLimitOfBeneficiaries(benef)) {
            return;
        }

        checkIfChangePlusAdditional(benef);

        beneficiaries.push(benef);
        setBeneficiaries([...beneficiaries]);
    };

    const editBeneficiary = (benef) => {
        const beneficiariesTemp = beneficiaries;

        beneficiariesTemp[rowIndexBeneficiary] = benef;

        if (benef.additionalFee) {
            if (hasExceededLimitOfBeneficiaries(benef)) {
                return;
            }

            checkIfChangePlusAdditional(benef);
        } else {
            checkIfRemoveBasicPlusAdditional(contractType, beneficiariesTemp);
        }

        setBeneficiaries([...beneficiariesTemp]);
    };

    const addOrEditBeneficiary = (benef) => {
        if (rowIndexBeneficiary === STATES_ROW_INDEX.NEW) {
            addBeneficiary(benef);
        } else {
            editBeneficiary(benef);
        }
        onHideAddingOrEditingBeneficiary();
    };

    const deleteBeneficiary = (index) => {
        const beneficiariesTemp = beneficiaries;
        beneficiariesTemp.splice(index, 1);
        setBeneficiaries([...beneficiariesTemp]);
        checkIfRemoveBasicPlusAdditional(contractType, beneficiariesTemp);
    };

    const onHideAddingOrEditingBeneficiary = () => {
        setRowIndexBeneficiary(STATES_ROW_INDEX.CLOSE);
    };

    const onError = (errors) => {
        showError("Debe completar los campos");
    };

    const onSubmit = (formData) => {
        formData.beneficiaries = beneficiaries;
        onSave(formData);
    };

    const actionBeneficiaryTemplate = (rowData, column) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="button-icon"
                    onClick={() => showEditBeneficiary(rowData, column.rowIndex)}
                />
                <Button
                    type="button"
                    className="p-button-danger button-icon"
                    icon="pi pi-trash"
                    onClick={() => deleteBeneficiary(column.rowIndex)}
                />
            </>
        );
    };

    return (<>
        <Dialog
            header="Agregar Beneficiario"
            visible={rowIndexBeneficiary !== STATES_ROW_INDEX.CLOSE}
            modal={true}
            onHide={onHideAddingOrEditingBeneficiary}
        >
            <BeneficiaryForm
                contractType={contractType}
                beneficiary={beneficiary}
                onChange={addOrEditBeneficiary}
                onBack={onHideAddingOrEditingBeneficiary}
            />
        </Dialog>
        <div className="col-12 md:col-2">
            <label htmlFor="input">{label}</label>
        </div>
        <div className="col-12 md:col-4">
            <Controller
                control={control}
                name="contractType"
                rules={{ required: true }}
                render={({ field }) => (
                    <Dropdown
                        id="contractType"
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Tipo"
                        className={classNames({
                            "p-invalid": !!errors.contractType,
                        })}
                        {...field}
                        options={contractTypes}
                        onChange={(e) => manualContractChange(e.value)}
                    />
                )}
            />
        </div>
        {children}
        <div className="spacer20" />
        <BeneficiaryDetails
            beneficiaries={beneficiaries}
            contractType={contractType}
        >
            <Column body={actionBeneficiaryTemplate} header="Opciones" />
        </BeneficiaryDetails>

        <div className="md:col-12">
            <Button label="Guardar" onClick={handleSubmit(onSubmit, onError)} />
            <Button
                type="button"
                label="Regresar"
                onClick={onBack}
            />
            <Button
                type="button"
                label="Agregar Beneficiarios"
                onClick={showAddBeneficiary}
            />
        </div>
    </>
    );
};

export default ContractTypeAndBeneficiaries;
