/*
 * Copyright (C) Acynonix Technologies S.A. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * Created  By Allan Moya <amoya@costacodecr.com>
 */

import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import useService from "../../hooks/hook-service";
import { formatCurrencyValue, formatDate } from "../../utils/format";

const BeneficiaryDetail = () => {
  const [beneficiary, setBeneficiary] = useState({});

  const navigate = useNavigate();
  const { post } = useService();
  const location = useLocation();

  useEffect(() => {
    post("beneficiary/get",
      {
        id: location.state.beneficiary.idCard,
      },
      ({ beneficiary }) => {
        setBeneficiary(beneficiary);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-fluid">
      <div className="grid">
        <div className="col-12">
          <div className="custom-card">
            <div className="custom-card-header-info">
              <h1>Detalle del Beneficiario</h1>
            </div>
            <div className="spacer20" />
            <div className="grid">
              {beneficiary?.kinship?.shouldAddIdCard && (
                <div className="md:col-4">
                  <label htmlFor="input">
                    Cédula: {beneficiary?.idCard ?? ""}
                  </label>
                </div>)}
              <div className="md:col-4">
                <label htmlFor="input">
                  Nombre: {beneficiary?.firstName ?? ""}{" "}
                  {beneficiary?.lastName ?? ""}
                </label>{" "}
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Fecha de nacimiento: {formatDate(beneficiary?.birthDate)}
                </label>{" "}
              </div>
              <div className="md:col-4">
                <label htmlFor="input">
                  Parentesco: {beneficiary?.kinship?.name ?? ""}
                </label>{" "}
              </div>
              {beneficiary?.additionalFee && (
                <>
                <div className="md:col-4">
                  <label htmlFor="input">
                    Cuota Adicional: {beneficiary?.additionalFee?.name ?? ""}
                  </label>
                </div>
                {beneficiary?.additionalFee.allowAnyAmount && (
                  <div className="md:col-4">
                    <label htmlFor="input">
                      Monto de Cuota: {formatCurrencyValue(beneficiary?.fee_amount)}
                    </label>
                  </div>
                )}
                </>
              )}
              <div className="spacer20" />
              <Button type="button" label="Regresar" onClick={goBack} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryDetail;
